import React from 'react'
import { Container, Text, Box } from 'theme-ui'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Container mt={[4, 5]} mb={[3, 4]}>
      <Box pb={[3, 3, 3, 4, 4]}>
        <Text as="h2" sx={{ variant: 'styles.h2' }}>
          NOT FOUND
        </Text>
      </Box>

      <Text as="p" sx={{ variant: 'styles.p' }}>
        You just hit a route that doesn&#39;t exist.
      </Text>
      <Box pt={[3, 3, 3, 3, 3]}>
        <Link to="/" title="homepage">
          <Text as="a" sx={{ variant: 'styles.a' }}>
            Go to homepage
          </Text>
        </Link>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
